import React, { useRef, useState } from 'react';
import "./Packages.css"; // Assuming your CSS is here

const PackageContainer = () => {

    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    const handleSubscribeButtonClick = () => {
        window.location.replace("https://app.queryloop-ai.com/auth/signup");
    };

    const containerRef = useRef();


    const handleMouseMove = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        setMousePosition({
            x: e.clientX - rect.left,
            y: e.clientY - rect.top,
        });
    };

    const glowStyle = {
        '--mouse-x': `${mousePosition.x}px`,
        '--mouse-y': `${mousePosition.y}px`,
    };


    return (
        <div className='pricing-page' data-aos="fade-down">
            <div
                ref={containerRef}
                id="enterpriseContainer"
                className="package-container"
                onMouseMove={handleMouseMove}
                style={glowStyle}
            >
                <div className='package_'>
                    <div className='package_header'>
                        <h2>Starter</h2>
                        {/* <img src={pro} alt='pro' /> */}
                    </div>

                    <div className='package_price'>
                        <div className='package_price_info'>
                            <p className='priceStyles'></p>
                            <div className='per-month'>
                                <p>Free trial with limitations</p>
                                {/* <p>month</p> */}
                            </div>
                        </div>
                        <button onClick={handleSubscribeButtonClick}>Subscribe</button>
                    </div>
                    <br />

                    <div className='package_includes'>
                        <p className='this-inlcudes'>This includes:</p>
                        <p > <span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Limited retrieval optimization. </p>
                        <p ><span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Limited generation optimization.</p>
                        <p ><span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Support for limited foundation models.</p>
                        <p ><span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Support for structured and unstructured data.</p>
                        <p ><span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Natural language database queries.</p>
                        <p ><span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ability to create a single application</p>
                        <p ><span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Set your own PineCone and OpenAI keys to remove limitations on the number of files, hyperparameter combinations, and benchmark answers.</p>

                    </div>
                </div>




            </div>

            <div
                ref={containerRef}
                id="enterpriseContainer"
                className="package-container"
                onMouseMove={handleMouseMove}
                style={glowStyle}
            >
                <div className='package_'>
                    <div className='package_header'>
                        <h2>Pro</h2>
                        {/* <img src={pro} alt='pro' /> */}
                    </div>

                    <div className='package_price'>
                        <div className='package_price_info'>
                            <p className='priceStyles'>USD 99</p>
                            <div className='per-month'>
                                <p>per month</p>
                                {/* <p>month</p> */}
                            </div>
                        </div>
                        <button onClick={handleSubscribeButtonClick}>Subscribe</button>
                    </div>
                    <br />

                    <div className='package_includes'>
                        <p className='this-inlcudes'>This includes:</p>
                        <p > <span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Retrieval optimization including identification of the optimal chunking strategy, embedding options, distance metrics, query preprocessing, and reranking methods.</p>
                        <p ><span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Generation optimization including identification of the best prompts and the most suitable LLMs.</p>
                        <p ><span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Support for major foundation models.</p>
                        <p ><span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Automatic embedding and LLM fine-tuning with Grid search and Bayesian optimization.</p>
                        <p ><span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Support for structured and unstructured data.</p>
                        <p ><span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Natural language database queries.</p>
                        <p ><span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Metadata filtering</p>
                        <p ><span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Access to beta features.</p>
                    </div>
                </div>




            </div>

            <div
                ref={containerRef}
                id="enterpriseContainer"
                className="package-container"
                onMouseMove={handleMouseMove}
                style={glowStyle}

            >
                <div className='package_'>
                    <div className='package_header'>
                        <h2>Enterprise</h2>
                        {/* <img src={enterprise} alt='enterprise' /> */}
                    </div>

                    <div className='package_price'>
                        <div className='package_price_info'>
                            <p className='priceStyles'>Contact us for pricing</p>

                        </div>
                        <button className='button' onClick={handleSubscribeButtonClick}>Contact us</button>
                    </div>
                    <br />
                    <div className='package_includes'>
                        <p className='this-inlcudes'>This includes:</p>
                        <p > <span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b >Everything in Pro Package plus the following:</b></p>
                        <p ><span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Extensive Custom Support by top LLM experts</p>
                    </div>


                </div>




            </div>



        </div>
    );
};

export default PackageContainer;
