import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import retrieval from "./assets/retrieval.png"
import finetuning from "./assets/finetuning.png"
import opensource from "./assets/opensource.png"
import video_ from "./assets/qlflow2.gif"

import './App.css';
import Navigation from './Components/Navigation/Navigation'
import Chat from './Components/Chat/Chat'
import Card from './Components/Card/Card'
import Footer from './Components/Footer/Footer'
import Packages from './Components/Packages/Packages'
import Integrations from './Components/Integrations/Integrations'
import Aos from 'aos'

import anime1 from "./assets/cardanime1.gif"
import anime2 from "./assets/cardanime2.gif"
import anime3 from "./assets/cardanime3.gif"

import chunk from "./assets/chunk.png"
import optimal from "./assets/optimal.png"
import details from "./assets/details.png"

import process from "./assets/process.png"
import costreduction from "./assets/costreduction.png"
import managerecord from "./assets/managerecord.png"

import llmfinetuning from "./assets/llmfinetuning.png"
import embedding from "./assets/embedding.png"
import ConsultancyPage from './Components/ConsultancyPage/ConsultancyPage';
import Blog from './Components/Blog/Blog';
import Post from './Components/Blog/Post/Post';
import SubscriptionComparisonTable from './Components/Packages/ComparisonTable/SubscriptionComparisonTable';



const cardsdata = [
    { title: "No more manual experiments", description: ["Eliminate the hassle of slow and manual RAG parameter tuning with our swift, automated solution", "Find the optimal configuration for your RAG application in seconds.", "Maximize efficiency with the best chunking, retrieval, and models."], anime: anime1, image: opensource, detailsImg1: details, detailsImg2: optimal, detailsImg3: chunk },
    { title: "Slash costs and time to market", description: ["Queryloop streamlines your search for the optimal RAG response.", "Achieve significant cost reductions by building production-grade LLM Apps within hours.", "Keep a clear and organized record of all experiments conducted, ensuring transparency and informed decision-making."], anime: anime2, image: retrieval, detailsImg1: process, detailsImg2: costreduction, detailsImg3: managerecord },
    { title: "Finetune over your data", description: ["Perform Embedding Optimization over your data to enhance retrieval accuracy.", "Perform LLM fine-tuning over your data to improve the generated response"], anime: anime3, image: finetuning, detailsImg1: llmfinetuning, detailsImg2: embedding },


]
// { title: "Access management", description: "Customize access for different company users. Assign permissions based on roles.", image: dashboard },
// { title: "Integration", description: "Bring in all your tools", image: integration },
// { title: "No more manual experiments", description: "Queryloop takes the pain away of identifying the right chunking strategy, retrieval/reranking, embedding model and LLM for your RAG app", image: opensource },
// { title: "Slash costs and time to market", description: ["Queryloop finds the best solution within hours drastically reducing the development cost and time while keeping track of all experiments that were run"], image: retrieval },
//  { title: "Custom Support", description: ["Need to further optimize your LLM app performance with the support of top LLM experts?", "We are just a click away"], anime: anime4, image: deployment },
function App() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <Router>
            <div className='main-section'>
                <Navigation />
                <Routes>
                    <Route path="/" element={<>
                        <Chat />
                        <div className='yt-demo' data-aos={"fade-up"} data-aos-delay={"0"}>
                            <div id='cards' className='demo card-container'>
                                <h2 className='text-shadow'>Queryloop Demo</h2>
                                {/* <p>See how Queryloop accelerates LLM optimization</p> */}
                            </div>
                            <iframe src="https://www.youtube.com/embed/pSkH4XBzBOM?si=LbaPvqc8UaTtnBVF" title="Queryloop demo" frameborder="0" allow="" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                        </div>
                        <div id='cards' data-aos={"fade-up"} data-aos-delay={"0"} className='card-container'>
                            <h2 className='text-shadow'>Why choose us?</h2>
                            {cardsdata.map((data, index) => (
                                <Card index={index} heading={data.title} subtitle={data.description} image={data.image} anime={data.anime} detailsImg1={data.detailsImg1} detailsImg2={data.detailsImg2} detailsImg3={data.detailsImg3} />
                            ))}
                        </div>

                        <div className='integrations'>
                            <Integrations />
                        </div>
                        <div className='full-flow' data-aos={"fade-up"} data-aos-delay={"150"}>
                            <img src={video_} alt='video_' />
                        </div>
                        <div className='package' id='package'>
                            <span class="top"></span>
                            <span class="right"></span>
                            <span class="bottom"></span>
                            <span class="left"></span>
                            <Packages />
                        </div>
                        <div className='subscription-table-pkg'>
                            <SubscriptionComparisonTable />
                        </div>
                    </>
                    } />
                    <Route path="/querylooplabs" element={<ConsultancyPage />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path='/blog/:id' element={<Post />} />
                </Routes>
                <Footer />
            </div>

        </Router>
    );
}

export default App;
